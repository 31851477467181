import * as model from './model.js';
import recipeView from './views/recipeView.js';
import searchView from './views/searchView.js';
import resultsView from './views/resultsView.js';
import bookmarksView from './views/bookmarksView.js';
import paginationView from './views/paginationView.js';
import addRecipeView from './views/addRecipeView.js';
import mainView from './views/mainView.js';
import { MODAL_CLOSE_SEC } from './config.js';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

// if (module.hot) {
//   module.hot.accept();
// }

///////////////////////////////////////

const controlRecipes = async function (
  recipeId = `${window?.location?.hash?.slice(1)}`
) {
  try {
    if (!recipeId) return;
    recipeView.renderSpinner();
    //Results view to mark select search results
    resultsView.update(model.getSearchResultsPage());
    //Render bookmarks
    bookmarksView.update(model.state.bookmarks);
    //Loading recipe
    await model.loadRecipe(recipeId);
    //Rendering the recipe
    recipeView.render(model.state.recipe);
  } catch (err) {
    console.log(err);
    recipeView.renderError();
  }
};

const controlSearchResults = async function () {
  try {
    resultsView.renderSpinner();
    const query = searchView.getQuery();
    if (!query) return;
    await model.loadSearchResults(query);
    // resultsView.render(model.state.search.results);
    resultsView.render(model.getSearchResultsPage());
    paginationView.render(model.state.search);
  } catch (err) {
    console.log(err);
  }
};

const controlPagination = function (goToPage) {
  if (!goToPage) return;
  resultsView.render(model.getSearchResultsPage(goToPage));
  paginationView.render(model.state.search);
};

const controlServings = function (newServings) {
  //Update recipe servings (in state)
  model.updateServings(newServings);
  //Update the recipe view
  // recipeView.render(model.state.recipe);
  recipeView.update(model.state.recipe);
};

const controlAddBookmark = function () {
  //Add / Remove bookmarks
  !model.state?.recipe?.bookmarked
    ? model.addBookmark(model.state.recipe)
    : model.deleteBookmark(model.state.recipe.id);

  //Update recipe view
  recipeView.update(model.state.recipe);

  //Render bookmarks
  bookmarksView.render(model.state.bookmarks);
};

const controlBookmarks = function () {
  bookmarksView.render(model.state.bookmarks);
};

/**
 * Uploading a new recipe to the API
 * @param {Object} newRecipe
 */
const controlAddRecipe = async function (newRecipe) {
  try {
    //Show loading spinner
    addRecipeView.renderSpinner();

    //Upload the data
    await model.uploadRecipe(newRecipe);

    //Render the recipe
    recipeView.render(model.state.recipe);

    //Success message
    addRecipeView.renderMessage();

    //Change ID in the URL
    window.history.pushState(null, '', `#${model.state.recipe.id}`);

    //Render bookmarksview
    bookmarksView.render(model.state.bookmarks);

    //Close modal window
    setTimeout(function () {
      addRecipeView.toggleWindow();
      addRecipeView.renderUploadForm();
    }, MODAL_CLOSE_SEC * 1000);
  } catch (err) {
    console.error('⛔', err);
    addRecipeView.renderError(err.message);
    setTimeout(function () {
      addRecipeView.renderUploadForm();
    }, MODAL_CLOSE_SEC * 1000);
  }
};

const logoClick = function () {
  window.location.hash = '';
  location.reload();
};

const init = function () {
  bookmarksView.addHandlerRender(controlBookmarks);
  recipeView.addHandlerRender(controlRecipes);
  recipeView.addHandlerUpdateServings(controlServings);
  recipeView.addHandlerAddBookmark(controlAddBookmark);
  searchView.addHandlerSearch(controlSearchResults);
  paginationView.addHandlerPagination(controlPagination);
  addRecipeView.addHandlerSubmitForm(controlAddRecipe);
  mainView.addHandlerLogoClick(logoClick);
  // model.clearBookmarks();
};

init();
