import View from './view';
import icons from 'url:../../img/icons.svg';

class PaginationView extends View {
  _parentElement = document.querySelector('.pagination');

  addHandlerPagination(handler) {
    this._parentElement.addEventListener('click', e => {
      e.preventDefault();
      const btn = e.target.closest('.btn--inline');
      const goToPage = +btn?.dataset?.goto;
      handler(goToPage);
    });
  }

  _generateMarkup() {
    const curPage = this._data.page;
    const numPages = Math.ceil(
      +this._data.results.length / this._data.resultsPerPage
    );
    // console.log(numPages);
    //Page 1, and there are other pages
    if (curPage === 1 && numPages > 1) {
      return this._generateButton();
    }
    //Page 1, and there are no other pages
    if (curPage === 1 && numPages <= 1) {
      return '';
    }
    //Last page
    if (curPage === numPages && numPages > 1) {
      return this._generateButton(false);
    }
    //Other page
    return this._generateBoth();
  }

  _generateButton(isNext = true) {
    const pageNumHTML = `
    <span>Page ${isNext ? this._data.page + 1 : this._data.page - 1}</span>
    `;
    return `
    <button data-goto="${
      isNext ? this._data.page + 1 : this._data.page - 1
    }" class="btn--inline pagination__btn--${isNext ? 'next' : 'prev'}">
    ${isNext ? pageNumHTML : ''}
    <svg class="search__icon">
        <use href="${icons}.svg#icon-arrow-${isNext ? 'right' : 'left'}"></use>
    ${isNext ? '' : pageNumHTML}
    </svg>
    </button>
    `;
  }

  _generateBoth() {
    const markup = `
    ${this._generateButton()}
    ${this._generateButton(false)}
    `;
    return markup;
  }
}

export default new PaginationView();
